<template>
  <div id="database_industry" class="container">
    <div class="search_box">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        宏观数据
      </div>
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item index="/database/MacroData/NationalEconomy"
            >国民经济</el-menu-item
          >
          <el-menu-item index="/database/MacroData/invest_consumption"
            >投资&消费</el-menu-item
          >
          <el-menu-item index="/database/MacroData/finance">金融</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div style="margin-top: 20px">
      <router-view> </router-view>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      activeIndex: this.$route.path,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>
<style lang="less" scoped>
#database_industry {
  color: #333;
  .search_box {
    border-radius: 5px;

    background: #fff;
    padding: 15px 20px 0px 20px;
    .titles {
      font-size: 20px;
      font-weight: 550;
    }
    .el-menu-item {
      font-size: 16px;
      font-weight: 550;
      color: #333;
      &:hover {
        background: #f5f7fa;
      }
      &.is-active {
        color: #1989fa;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}
</style>
